<template>
  <t-page>
    <h3 slot="header">
      {{ $t('pages.account.danger_zone.heading') }}
    </h3>
    <t-form-card>
      <template #header>
        <h4>
          {{ $t('pages.account.danger_zone.actions.delete') }}
        </h4>
      </template>
      <t-button-loading
        :loading="loading"
        type="danger"
        block
        @click.prevent="confirmationVisible = true"
      >
        {{ $t('pages.account.danger_zone.actions.delete_link') }}
      </t-button-loading>
      <portal to="modals">
        <t-confirm
          v-if="confirmationVisible"
          :confirmation="$auth.user.email"
          :button-label="$t('global.actions.confirm_deletion')"
          :confirmation-placeholder="$t('fields.type_confirmation')"
          @confirmed="onConfirmed"
          @cancelled="confirmationVisible = false"
        >
          <template #header>
            {{ $t('pages.account.danger_zone.actions.delete') }}
          </template>
          <p>
            {{ $t('pages.account.danger_zone.confirmation.are_you_sure') }}<br>
            {{ $t('pages.account.danger_zone.confirmation.action_is_final') }}<br>
            {{ $t('pages.account.danger_zone.confirmation.email_confirmation') }}<br>
          </p>
        </t-confirm>
      </portal>
    </t-form-card>
  </t-page>
</template>
<script>
import Vue from 'vue';
import TFormCard from '~/components/TFormCard.vue';
import Applicant from '~/models/Applicant';
import Employee from '~/models/Employee';

export default Vue.extend({
  components: {
    TFormCard,
  },

  data: () => ({
    loading: false,
    confirmationVisible: false,
  }),

  methods: {
    async onConfirmed() {
      this.loading = true;
      try {
        this.confirmationVisible = false;

        await this.deleteUser();

        await this.$notify.success(this.$t('notifications.account.deleted'));

        await this.$router.push({ name: this.$RouteEnum.LOGOUT });
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },

    deleteUser() {
      if (this.$auth.isApplicant) {
        return this.deleteApplicant();
      } else {
        return this.deleteEmployee();
      }
    },

    async deleteApplicant() {
      return await new Applicant({ id: this.$auth.me.id }).delete();
    },

    async deleteEmployee() {
      return await new Employee({ id: this.$auth.me.id }).delete();
    },
  },
});
</script>
